@import url('https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-bg1: rgb(108, 0, 162);
    --color-bg2: rgb(0, 17, 82);
    --color1: 18, 113, 255;
    --color2: 221, 74, 255;
    --color3: 100, 220, 255;
    --color4: 200, 50, 50;
    --color5: 180, 180, 50;
    --color-interactive: 140, 100, 255;
    --circle-size: 80%;
    --blending: hard-light;
}

* {
    font-family: 'Saira Extra Condensed', sans-serif;
}
html {
    scroll-behavior: smooth;
}

html::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

html::-webkit-scrollbar,
div::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

html::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}

html {
    font-family: 'Saira Extra Condensed', sans-serif;
    scroll-behavior: smooth;
}

.nav-menu::after {
    content: '';
    width: 100%;
    height: 4px;
    background-color: #f97316;
    border-radius: 3px;
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: scaleX(0);
    transition: transform 0.4s linear;
    transform-origin: right;
}
.nav-menu.active {
    cursor: pointer;
    transition: 0.4s linear;
}

.nav-menu.active::after {
    transform: scaleX(1);
    transform-origin: left;
}

.icon-dark {
    color: '#fff';
}
